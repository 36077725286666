<template>
    <div class="autocomplete">
        <input
                type="text"
                v-model="search"
                @focus="loadData"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                class="form-control"
                readonly
                />
        <ul id="autocomplete-results"  v-show="results.length > 0" class="autocomplete-results">
            <li class="loading" v-if="isLoading">
                Loading results...
            </li>
            <li v-else
                v-for="(result, i) in results"
                :key="i"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }"
                    >
                <template v-if="result.id!=0">
                <b-form-checkbox
                        v-model="result.status"
                        @change="setResult(result)"
                        >
                    {{ result.name }}
                </b-form-checkbox>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>

    import Axios from 'axios';

    export default {
        name: 'regionForm',
                data() {
            return {
                results: [],
                selects: [],
                search: '',
                isLoading: false,
                arrowCounter: -1
            };
        },
        props: {
            items: {      // items.id наличие этого в
                type:       Array,
                        required:   false,
            default:    []
            },

            title: {
                type:       String,
                        required:   false,
            default:    ''
            }
        },

        mounted() {
            document.addEventListener('click', this.handleClickOutside);
            this.search = this.title;
        },

        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        },

        methods: {
            loadData(){
                let _this = this;

                this.results = this.regions;
                _this.results.forEach(function (data) {
                    var obj = _this.items.find(item => item.id === data.id);
                    if (typeof obj !== 'undefined') data.status = true;else data.status = false;
                });

            },

        setTitle(){
            let s = [];
            let _this = this;
            this.results.forEach(function (data) {
                if (data.status) s.push(data.name);
            });
            this.search = s.join(', ');
        },

        setResult(result) {
            this.setTitle();
            if (result.status) {
                this.$emit('addProduct', result);
            } else {
                this.$emit('delProduct', result);
            }
        },

        onChange() {
            this.$emit('input', this.search);
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.results = [];
                this.arrowCounter = -1;
            }
        },

        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },

        onEnter() {
//                this.search = this.results[this.arrowCounter];
            this.results = [];
            this.arrowCounter = -1;
        },

        sort() {
            this.results.sort(function (a, b) {
                let c1 = 0;
                let c2 = 0;

                if (a.children === false) c1=0;else c1=a.children.length;
                if (b.children === false) c2=0;else c2=b.children.length;

                if (c1 < c2) return 1;
                else if (c1 > c2) return -1;
                else return 0;
            });
        }
    },

    computed : {
        regions: function () {
            let loc = this.$store.getters.getLocal;
            return loc.regions;
        },

        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
    },
    watch: {
        title: function (val) {
            this.search = this.title;
        },
        items: function (val) {
            this.setTitle();
        }
    }
    }
</script>

<style>
    .autocomplete {
        position: relative;
        width: 100%;
    }

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 220px;
        overflow: auto;
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 99;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background-color: #4AAE9B;
        color: white;
    }
</style>
