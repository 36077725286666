<template>
    <div class="autocomplete">
        <div class="input-group">
            <input
                type="text"
                @input="loadStates"
                v-model="search"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"

                v-bind:class="{ 'is-invalid': (isError == -1), 'is-valid': (isError == 1), 'form-control': true }"
                />
            <div class="input-group-append" >
                <button v-if="current_phone.length > 0" class="btn btn-success " type="button" id="button-addon2" @click="init()">{{current_phone}}</button>
            </div>
        </div>
        <ul id="autocomplete-results"  v-show="results.length > 0" class="autocomplete-results2">
            <li class="loading" v-if="isLoading">
                Loading results...
            </li>
            <li v-else
                v-for="(result, i) in results"
                :key="i"
                @click="setResult(result)"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }"
                    >
                {{ result.name }} ({{ result.phone }}
                <template v-if="result.club_card!=''">
                    , Card valid until {{ result.club_card }}
                </template>
                )
            </li>
        </ul>
    </div>
</template>

<script>
    import debounce from 'debounce';

    export default {
        name: 'phoneForm',
        props: ['bus'],

        data() {
            return {
                results: [],
                search: '',
                isLoading: false,
                arrowCounter: -1,
                isError: 0
            };
        },

        mounted() {
            document.addEventListener('click', this.handleClickOutside);
            this.bus.$on("set_phone", (phone) => {

                this.search = phone;
                this.current_phone = '';
                this.$emit('setPhone', this.search);

                this.checkValue(phone);
            });
        },

        destroyed() {

            this.destroy();
        },

        methods: {
            destroy() {
                document.removeEventListener('click', this.handleClickOutside);
                this.bus.$off('set_phone');
            },

            checkValue(val){
                if (val == '') this.isError = 0;
                else if (this.ValidPhone(val)) this.isError = 1;
                else this.isError = -1;
            },

            init(){
                this.search = this.current_phone;

                this.checkValue(this.search);
                this.isLoading = true;
                this.$http
                        .get(this.prefix_url + '/api/customers?type=query&query='+this.search)
                        .then(response => {
                            this.results = response.data.data;
                            this.isLoading = false;
                });
                this.current_phone = '';
                this.$emit('setPhone', this.search);
            },

            ValidPhone(phone) {
                let str = phone.replace(/\D/g, '');
                var re = /^\d{9}$/;
                if (typeof process.env.VUE_APP_COUNTRY === 'undefined' || process.env.VUE_APP_COUNTRY == 'uz' || process.env.VUE_APP_COUNTRY == 'pl') re = /^\d{9}$/;
                else if (process.env.VUE_APP_COUNTRY == 'kz') re = /^\+?7?\d{10}$/;
                else re = /^\+?7?\d{10}$/;

                return re.test(phone);
            },

            loadStates: function () {
                this.checkValue(event.target.value);
                this.isLoading = true;
                debounce((function (_this, event) {
                    _this.$emit('setPhone', event.target.value);
                    if (event.target.value.length > 2) {

                        _this.$http
                                .get(_this.prefix_url + '/api/customers?type=query&query='+event.target.value)
                                .then(response => {
                            _this.results = response.data.data;
                        _this.isLoading = false;
                    })
                }else {
                    _this.isLoading = false;
                    _this.results = [];
                }
            })(this, event), 200)
        },

        setResult(result) {
            this.$emit('result', result);
            this.search = result.phone;
            this.results = [];
            this.checkValue(this.search);
        },

        onChange() {
            this.$emit('input', this.search);
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.results = [];
                this.arrowCounter = -1;
            }
        },

        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },

        onEnter() {
            this.search = this.results[this.arrowCounter];
            this.results = [];
            this.arrowCounter = -1;
        },
    },

    computed : {
        current_phone: {
            get () { return this.$store.getters.getCurrentPhone; },
            set(value) { this.$store.dispatch('setCurrentPhone', value); }
        },

        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
    }
    }
</script>

<style>
    .autocomplete {
        position: relative;
        width: 100%;
    }

    .autocomplete-results2 {
        padding: 0;
        margin: 0;
        border: 1px solid black;
        height: 220px;
        overflow: auto;
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 99;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background-color: #4AAE9B;
        color: white;
    }
</style>
