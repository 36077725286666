import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import bus from "@/bus";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userName:               localStorage.getItem('userName') || '',
        userId:                 localStorage.getItem('userId') || '',
        token:                  localStorage.getItem('token') || '',
        rules:                  JSON.parse(localStorage.getItem('rules')) || [],
        etherId:                localStorage.getItem('ether_id') || 0,
        filterPanelProducer:    localStorage.getItem('filter_panel_producer') || 'all',
        filterPanelProducer2:   localStorage.getItem('filter_panel_producer2') || 'work',
        local:                  JSON.parse(localStorage.getItem('local')) || {},
        locale:                 localStorage.getItem('locale') || 'en_US',
        cView:                  '',
        currentPhone:           '',
        acceptTicket:           0,
        orderBus:               undefined,
        TZ:                     Intl.DateTimeFormat(undefined,{timeZoneName: "short"}).formatToParts().find((i) => i.type === "timeZoneName").value
    },

    mutations: {
        auth_request(state){
            state.status = 'loading';
        },

        auth_success(state, data) {
            state.status   = 'success';
            state.token    = data.token;
            state.userId   = data.userId;
            state.userName = data.userName;
            state.rules    = data.rules;
            state.local    = data.local;
            state.locale   = data.locale;
        },

        auth_error(state) {
            state.token    = '';
            state.status   = 'error';
            state.userName = '';
            state.rules    = [];
            state.local    = {};
        },

        logout(state) {
            state.token    = '';
            state.status   = '';
            state.userName = '';
            state.rules    = [];
//            state.local    = {};
        },

        set_ether(state, etherId) {
    	    state.etherId = etherId;
        },

        set_filter_panel_producer(state, value) {
            state.filterPanelProducer = value;
        },

        set_filter_panel_producer2(state, value) {
            state.filterPanelProducer2 = value;
        },

        setCView(state, value) {
            state.cView = value;
        },

        setCurrentPhone(state, value) {
            state.currentPhone = value;
        },

        setLocal(state, value) {
            state.local = value;
        },

        setLocale(state, value) {
            state.locale = value;
        },

        setAcceptTicket(state, value) {
            state.acceptTicket = value;
        },

        setTZ(state, value) {
            state.TZ = value;
        },

        setOrderBus(state, value) {
            state.orderBus = value;
        },
    },

    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios.post(process.env.VUE_APP_PREFIX_URL + '/api/login?ts='+Date.now(), user, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => {
                        if (process.env.NODE_ENV !== 'production') console.log("Auth: ", resp);
                        if (resp.data.success) {

                            const token     = resp.data.token;
                            const userId    = resp.data.user_id;
                            const userName  = resp.data.user;
                            const rules     = JSON.parse(resp.data.rules);
                            const loc       = resp.data.local;
                            const locale    = resp.data.locale;

                            localStorage.setItem('token', token);
                            localStorage.setItem('userId', userId);
                            localStorage.setItem('userName', userName);
                            localStorage.setItem('rules', resp.data.rules);
                            localStorage.setItem('local', JSON.stringify(resp.data.local));
                            localStorage.setItem('locale', resp.data.locale);
                            axios.defaults.headers.common['Authorization'] = token;
                            commit('auth_success', { token: token, userId: userId, userName: userName, rules: rules, local: loc, locale: locale });
                            resolve(resp);
                        }else{
                            reject(resp);
                        }
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject(err);
                    })
            })
        },

        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('userName');
                localStorage.removeItem('locale');
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            })
        },

        setEthers({commit}, ether_id) {
            return new Promise((resolve) => {
    		commit('set_ether', ether_id);
    		localStorage.setItem('ether_id', ether_id);
    		resolve();
    	    })
        },

        setFilterPanelProducer({commit}, value) {
            return new Promise((resolve) => {
                commit('set_filter_panel_producer', value);
                localStorage.setItem('filter_panel_producer', value);
                resolve();
            })
        },

        setFilterPanelProducer2({commit}, value) {
            return new Promise((resolve) => {
                commit('set_filter_panel_producer2', value);
                localStorage.setItem('filter_panel_producer2', value);
                resolve();
            })
        },

        setCView({commit}, value) {
            return new Promise((resolve) => {
                commit('setCView', value);
                resolve();
            })
        },

        setCurrentPhone({commit}, value) {
            return new Promise((resolve) => {
                commit('setCurrentPhone', value);
                resolve();
            })
        },

        setLocale({commit}, value) {
            return new Promise((resolve) => {
                commit('setLocale', value);
                localStorage.setItem('locale', value);
                resolve();
            })
        },

        setAcceptTicket({commit}, value) {
            return new Promise((resolve) => {
                commit('setAcceptTicket', value);
                resolve();
            })
        },

        setTZ({commit}, value) {
            return new Promise((resolve) => {
                commit('setTZ', value);
                resolve();
            })
        },

        setOrderBus({commit}, value) {
            return new Promise((resolve) => {
                commit('setOrderBus', value);
                resolve();
            })
        },
    },

    getters : {
        token: state => state.token,

        getUserId: state => state.userId,

        isLoggedIn: state => !!state.token,

        authStatus: state => state.status,

        getUserName: state => state.userName,

        getEtherId: state => state.etherId,

        getFilterPanelProducer: state => state.filterPanelProducer,

        getFilterPanelProducer2: state => state.filterPanelProducer2,

        getCView: state => state.cView,

        getCurrentPhone: state => state.currentPhone,

        getLocal: state => state.local,

        getLocale: state => state.locale,

        getAcceptTicket: state => state.acceptTicket,

        getTZ: state => state.TZ,

        getOrderBus: state => state.orderBus,

        getRule: (state) => (name) => {
            if (state.rules.length === 0) return false;
            var _rule = state.rules.find(rule => rule.name === name);
            if (typeof _rule !== 'undefined') {
                return _rule.access;
            }
            return false;
        }

    }
})
