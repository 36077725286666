<template>
    <div class="autocomplete">
        <input
                type="text"
                @input="loadStates"
                v-model="search"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                class="form-control"
                placeholder="Начните вводить название товара"
                />
        <ul id="autocomplete-results"  v-show="results.length > 0" class="autocomplete-results">
            <li class="loading" v-if="isLoading">
                Loading results...
            </li>
            <li v-else
                v-for="(result, i) in results"
                :key="i"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }"

                    >
                <template v-if="result.children === false">
                        <span @click="setResult(result)">{{ result.name_s }}</span>
                </template>
                <template v-else>
                    <ul type="none">
                        <li>
                            <b><a href="#" v-on:click.prevent="expand(result, $event)">{{ result.name }}</a></b>
                        </li>
                        <li v-if="result.expanded" v-for="(r, i0) in result.children" :key="i0" @click="setResult(r)" class="autocomplete-result">
                                {{ r.name }}
                        </li>
                    </ul>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
    import debounce from 'debounce';

    export default {
        name: 'productOneForm',
                data() {
            return {
                results: [],
                search: '',
                isLoading: false,
                arrowCounter: -1
            };
        },
        watch: {
            title(val){
                this.search = val;
            }
        },

        props: {
            title: {
                type: String,
                required: true,
                default: ''
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },

        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        },

        methods: {
            loadStates: function () {
                this.isLoading = true;
                debounce((function (_this, event) {
                    if (event.target.value.length > 3) {
                        _this.$http
                                .get(_this.prefix_url + '/api/products2?query='+event.target.value+'&site=&details=true')
                                .then(response => {
                            _this.results = response.data.products;
                        console.log('1');

                        _this.sort();
                        _this.isLoading = false;
                    })
                }else if (event.target.value.length == 0) {
                    _this.isLoading = false;
                    _this.results = [];
                    _this.$emit('select', { id: '', name: '' });
                }else{
                    _this.isLoading = false;
                    _this.results = [];
                }
            })(this, event), 200)
        },

        setResult(result) {
//            this.search = result.name_s;
            this.results = [];
            this.$emit('select', result);
        },

        onChange() {
            this.$emit('input', this.search);
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.results = [];
                this.arrowCounter = -1;
            }
        },

        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },

        onEnter() {
            this.search = this.results[this.arrowCounter];
            this.results = [];
            this.arrowCounter = -1;
        },

        expand(product, e){
            product.expanded = !product.expanded;
        },

        sort() {
            this.results.sort(function (a, b) {
                let c1 = 0;
                let c2 = 0;

                if (a.children === false) c1=0;else c1=a.children.length;
                if (b.children === false) c2=0;else c2=b.children.length;

                if (c1 < c2) return 1;
                else if (c1 > c2) return -1;
                else return 0;
            });
        }
    },

    computed : {
        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
    }
    }
</script>

<style>
    .autocomplete {
        position: relative;
        width: 100%;
    }

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 220px;
        overflow: auto;
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 99;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background-color: #4AAE9B;
        color: white;
    }
</style>
