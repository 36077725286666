<template>
    <div>
        <b-modal id="bv-modal-show-customer" size="lg"  no-close-on-esc>
            <template #modal-header>
                <b-container fluid>
                <b-row>
                    <b-col sm="9" class="text-left">Редактирование клиента: {{customer.name}}</b-col>
                    <b-col sm="3" class="text-right">
                        <b-button-group>
                            <b-button :class="{'btn-success': customer.rating == 'vip'}" @click="changeRating('vip')">VIP</b-button>
                            <b-button :class="{'btn-danger': customer.rating == 'bad'}" @click="changeRating('bad')">BAD</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                </b-container>
            </template>
            <template #modal-footer>
                <b-button variant="success" @click="saveChanges" :disabled="!customer.editable">{{$t('main.save')}}</b-button>
                <b-button variant="danger" style="margin-left: 10px"  @click="$bvModal.hide('bv-modal-show-customer')">{{$t('main.close')}}</b-button>
            </template>

            <div class="modal-body">
                <b-overlay :show="loading" rounded="sm">
                                <b-container fluid>
                                    <b-form-group :disabled="!customer.editable" >
                                        <b-row class="my-1">
                                            <b-col sm="3">
                                                <p>{{$t('order.form.data.name')}}:</p>
                                            </b-col>
                                        <b-col sm="9">
                                            <b-form-input v-model="customer.firstName"></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.surname')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input v-model="customer.lastName"></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.phone')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-tags
                                                    input-id="tags-separators"
                                                    v-model="customer.phone"
                                                    :placeholder="$t('main.phone')"
                                                    tag-variant="primary"
                                                    duplicate-tag-text=""
                                                    :invalid-tag-text="$t('order.form.data.invalid_phone_number_format')"
                                                    :tag-validator="phoneValidate"

                                                    ></b-form-tags>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.club_card')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input v-model="customer.club_card" type="date" :disabled="!rule_edit_club_card"></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.date_of_birth')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input v-model="customer.birthdate" type="date"></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.client_gender')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-select
                                                    v-model="customer.sex"
                                                    :options=options_select_sex>

                                                <!-- These options will appear after the ones from 'options' prop -->

                                            </b-form-select>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1" v-if="country === 'pl'">
                                            <b-col sm="3">
                                                <p>{{$t('order.form.data.postcode')}}:</p>
                                            </b-col>
                                            <b-col sm="9">
                                                <b-form-input v-model="customer.postcode"></b-form-input>
                                            </b-col>
                                    </b-row>


                                    <b-row>
                                        <div class="col-sm-3">
                                                {{$t('order.form.data.region')}}:
                                        </div>
                                        <b-col sm="9">
                                                <div class="input-group">
                                                    <b-form-select class="form-control" v-model="customer.region_name" id="region" ref="region" tabindex="11" :options="regions">
                                                    </b-form-select>
                                                </div>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.city')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <div class="input-group">
                                                <cityForm
                                                        id="city"
                                                        ref="city"
                                                        :isError="false"
                                                        :region="customer.region_name"
                                                        :city="customer.city_name"
                                                        :tabindex="12"
                                                        @result="setCity"
                                                        >
                                                </cityForm>

                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col sm="3">
                                            <p>{{$t('order.form.data.address')}}:</p>
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input v-model="customer.address"></b-form-input>
                                        </b-col>
                                    </b-row>

                                </b-form-group>
                            </b-container>
            </b-overlay>
        </div>
        </b-modal>
    </div>
</template>

<style>

</style>
<script>
    import Vue from 'vue'
    import Axios from 'axios';
    import cityForm from "@/components/cityForm.vue";
    import bus from "@/bus";

    export default {
        name: 'Customer',

        components: {
            cityForm
        },

        data() {
            return {
                loading: false,
                customer_id: 0,
                customer: {
                    phone:          [],
                    firstName:      '',
                    lastName:       '',
                    name:           '',
                    sex:            '',
                    birthdate:      '',
                    club_card:      '',
                    city_id:        '',
                    city_name:      '',
                    area_name:      '',
                    region_name:    '',
                    postcode:       '',
                    address:        '',
                    rating:         '',
                    editable:       false,
                },
                options_select_sex:
                        [
                            {
                                "text": this.$t('order.form.data.male'),
                                "value":"male"
                            },
                            {
                                "text": this.$t('order.form.data.female'),
                                "value":"female"
                            }
                        ]

            }
        },
        props: [ 'bus' ],

        mounted() {

            this.bus.$on("init", (customer_id) => {
                this.customer_id = customer_id;
                this.loadData(customer_id);
            });
        },

        beforeRouteLeave (to, from, next) {
            this.bus.$off('init');
            next();
        },

        destroyed() {
        },

        methods: {
            changeRating(rating){
                if (this.customer.rating == 'vip' && rating == 'vip') {
                    this.customer.rating = '';
                    return;
                } else if (this.customer.rating == 'vip' && rating == 'bad') {
                    this.customer.rating = 'bad';
                    return;
                } else if (this.customer.rating == 'bad' && rating == 'bad') {
                    this.customer.rating = '';
                    return;
                } else if (this.customer.rating == 'bad' && rating == 'vip') {
                    this.customer.rating = 'vip';
                    return;
                } else if (this.customer.rating == '' && rating == 'bad') {
                    this.customer.rating = 'bad';
                    return;
                } else if (this.customer.rating == '' && rating == 'vip') {
                    this.customer.rating = 'vip';
                    return;
                }
            },

            loadData (customer_id) {
                this.loading = true;

                let url = this.prefix_url +
                     "/api/customers/?type=load&customer_id=" + customer_id + "&ts=" + Date.now();

                Axios
                    .get(url)
                    .then(response => {
                        if (response.data.success === true) {
                             this.customer = response.data.data;
                             this.$root.$emit('bv::show::modal', 'bv-modal-show-customer');
                        } else bus.$emit('view-message', { message: (this.$t('main.error_toast')), variant: 'danger' });
                        this.loading = false;
                });
            },

            saveChanges () {
                let url = this.prefix_url +
                     "/api/customers/?method=save&ts="+Date.now();

                Axios
                    .post(url, this.customer)
                    .then(response => {
                        let resp = response.data;
                        if (resp.success !== true) {
                            bus.$emit('view-message', {message: (this.$t('main.error_toast')), variant: 'danger'});
                        }else {
                            this.customer.name = this.customer.lastName+' '+ this.customer.firstName;
                            this.customer.phones = this.customer.phone.join(", ");

                            this.$emit('save', this.customer);
                            this.loadData(this.customer_id)
                        }
                });
            },

            phoneValidate (num) {
                let str = num.replace(/\D/g, '');
                var re = /^\d{9}$/;
                if (this.country === 'uz' || this.country === 'pl') re = /^\d{9}$/; else re = /^\+?7?\d{10}$/;
                return re.test(num);
            },

            setCity(city) {
                this.customer.city_obj      = city;
                this.customer.city_id       = city.city_id;
                this.customer.city_name     = city.city_name;
                this.customer.region_name   = city.region_name;
            }
        },

        computed : {
            regions: function () {
                let loc = this.$store.getters.getLocal;
                return loc.regions;
            },

            rule_edit_club_card: function (){return this.$store.getters.getRule('rule_edit_club_card') },

            prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },

            country: function () {
                if (typeof process.env.VUE_APP_COUNTRY === 'undefined') return '';
                return process.env.VUE_APP_COUNTRY;
            },
        },
    }
</script>
