<template>
    <b-modal id="modaladdProductEther" size="xl" title="Добавление товара" no-close-on-esc hide-footer>
        <template #modal-header="{ close }">
            <b-container fluid>
            <b-row>
                <b-col v-if="ether_id > 0">
                    <h5>{{$t('add_product.adding_a_product_to_the_broadcast')}}</h5>
                </b-col>
                <b-col v-else>
                    <h5>{{$t('add_product.free_product_addition')}}</h5>
                </b-col>
                <b-col cols="8" class="text-right" style="padding-right: 0px;">
                    <b-form inline style="display: block">
                        <button type="button" :class="{btn: true, 'btn-info': !free_sale, 'btn-success': free_sale }" @click="free_sale = ! free_sale" style="margin-right: 10px">
                            Free Sale
                        </button>
                        <b-form-input v-if="ether_id > 0 && ! free_sale" :placeholder="$t('add_product.catalog_search')" v-model="filter" size="sm" style="width: 70%; margin-right: 10px"></b-form-input>
                        <b-form-input v-else :placeholder="$t('add_product.catalog_search')" @input="searchData" v-model="filter" size="sm" style="width: 70%; margin-right: 10px"></b-form-input>
                        <b-button size="sm" @click="close()" >
                            <b-icon-x></b-icon-x>
                        </b-button>
                    </b-form>
                </b-col>
            </b-row>
            </b-container>
        </template>

        <div class="container-flex">
            <div class="row" v-if="loading">
                <div class="col-sm-12">
                    <h5>{{$t('main.loading_data')}}</h5>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-sm-12">
                    <table class="table table-sm" id="tableProductEther">
                        <thead>
                        <tr>
                            <th scope="col" width="10%">{{$t('main.picture')}}</th>
                            <th scope="col" width="60%">{{$t('main.product')}}</th>
                            <th scope="col" width="10%">{{$t('main.quantity')}}</th>
                            <th scope="col" width="5%">{{$t('main.in_stock')}}</th>
                            <th scope="col" width="15%">{{$t('main.price')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(product, index) in filtredData" :key="product.id" v-bind:class="{ 'alert-success': product.selected, 'at-active-ether': product.active=='1' }">
                            <template v-if="product.children===false" >
                                <template v-if="product.id!=-1">
                                    <td width="10%"><ExpandImage width="100" thumbnail fluid :src="product.image"></ExpandImage></td>
                                    <td width="60%">
                                      <a href="#" @click="selectProduct(product, $event)">{{ product.name }}</a>
                                      <template v-if="product.not_use_discount=='true'">
                                        <b-icon-shield-x></b-icon-shield-x>
                                      </template>
                                      <br/>{{product.description}}
                                    </td>
                                    <td width="10%" align="center"><input type="number" min="0" :max="product.stock" style="width: 50px" v-model="product.quantity" :disabled="product.stock==0" class="text-center" @change="changeProduct(product, $event)"></td>
                                    <td v-if="rule_tv_admin" width="5%" align="center"><nobr>{{ product.stock }} / {{product.reserved}}</nobr></td>
                                    <td v-else width="5%" align="center">{{ product.stock }}</td>
                                    <td width="15%" align="right">{{ product.amount | formatNumber }} {{currency}}</td>
                                </template>
                                <template v-else>
                                    <td width="10%"></td>
                                    <td width="60%"></td>
                                    <td width="10%"></td>
                                    <td width="5%"></td>
                                    <td width="15%"></td>
                                </template>
                            </template>
                            <template v-else>
                                <td colspan="5">
                                    <table width="100%">
                                        <tr>
                                            <td width="10%" style="border: solid 0px white"><ExpandImage width="100" thumbnail fluid :src="product.image"></ExpandImage></td>
                                            <td width="90%" colspan="4" style="border: solid 0px white">
                                                <a href="#" @click="expandProduct(product, $event)">
                                                    <template v-if="product.expanded">
                                                        <b>{{ product.name }}</b>
                                                      <template v-if="product.not_use_discount=='true'">
                                                        <b-icon-shield-x></b-icon-shield-x>
                                                      </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ product.name }}
                                                    </template>

                                                </a>
                                                <br/>{{product.description}}
                                            </td>
                                        </tr>
                                        <tr v-if="product.expanded" v-for="(p, i) in product.children" v-bind:class="{ 'alert-success': p.selected }">
                                            <td width="10%"></td>
                                            <td width="60%"><a href="#" @click="selectProduct(p, $event)">{{ p.name }}</a></td>
                                            <td width="10%" align="center"><input type="number" min="0" :max="p.stock" style="width: 50px" v-model="p.quantity" :disabled="p.stock==0" class="text-center" @change="changeProduct(p, $event)"></td>
                                            <td v-if="rule_tv_admin" width="5%" align="center"><nobr>{{p.stock}} / {{p.reserved}}</nobr></td>
                                            <td v-else width="5%" align="center">{{p.stock}}</td>
                                            <td width="15%" align="right">{{ p.amount | formatNumber }} {{currency}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import Vue from 'vue';
    import Axios from 'axios';
    import bus from "../bus";
    import debounce from 'debounce';
    import ExpandImage from "@/components/ExpandImage.vue";

    Vue.filter("formatNumber", function (value) {
        return new Intl.NumberFormat().format(value);
    });

    export default {
        name: 'addProductEther',
        components: {
            ExpandImage
        },
        data: function () {
            return {
                treeData: [],
                products: [],               // массив товаров
                pageNumber: 0,              // текущая страница
                filter: '',                 // фильтр
                loading: true,
                data: [],
                ether_id: 0,
                free_sale: false
            }
        },

        mounted() {
            this.loadData();
        },

        props: {
            details: {
                type: Boolean,
                required: false,
                default: true
            },

            skip_search: {
                type: Boolean,
                required: false,
                default: false
            },

            site: {
                type: String,
                required: true,
                default: ''
            },

            size: {
                type: Number,
                required: false,
                default: 30
            },
        },

        mounted: function(){
            bus.$on("update-ether", (ether_id) => {
                this.ether_id = ether_id;
                this.loadData();
            });
        },

        beforeRouteLeave (to, from, next) {
            bus.$off('update-ether');
            next();
        },

    methods: {
        loadData(){
            this.loading = true;
            Axios
                .get(this.prefix_url + '/api/products2?ts=' + Date.now()+'&site='+this.site+'&details='+this.details+'&ether_id='+this.ether_id)
                .then(response => {
                    this.products = response.data.products;
                    this.loading = false;
                    this.filter = '';
            });
        },

        searchData(){
            debounce((function (_this, event) {
                _this.$http
                    .get(_this.prefix_url + '/api/products2?ts=' + Date.now() + '&query='+event.target.value+'&site='+_this.site+'&details='+_this.details)
                    .then(response => {
                        _this.products = response.data.products;
                });
            })(this, event), 200);
        },

        selectProduct(product, e){
            let value = e.target.value;

            if (product.redirect != '') {
                bus.$emit('view-message', { message: product.redirect, variant: 'danger' });
                return;
            }

            if (product.stock == 0) {
                bus.$emit('view-message', { message: this.$t('add_product.product_not_added_no_remaining_balance'), variant: 'danger' });
                return;
            }
            if (!product.selected) {
                product.selected = true;
                this.$emit('addProduct', product);
                this.data.push({ id: product.id, quantity: product.quantity });
            } else {
                product.selected = false;
                product.quantity = 1;
                this.$emit('delProduct', product);
            }
        },

        changeProduct(product, e){
            if (product.redirect != '') {
                bus.$emit('view-message', { message: product.redirect, variant: 'danger' });
                return;
            }
            let value = e.target.value;
            product.quantity = value;
            product.selected = true;
            this.$emit('addProduct', product);
        },

        expandProduct(product, e){
            product.expanded = !product.expanded;
        },

        preload() {
            var _this = this;
            if (this.data.length == 0) return;

            this.products.forEach(function (product) {
                var obj = _this.data.find(item => item.id === product.id);
                if (typeof obj !== 'undefined') {
                    product.selected = true;
                    product.quantity = item.quantity;
                }else{
                    if (typeof product.children !== 'undefined' && product.children !== false) {
                        product.children.forEach(function (product0) {
                            var obj0 = _this.data.find(item => item.id === product0.id);
                            if (typeof obj0 !== 'undefined') {
                                product0.selected = true;
                                product0.quantity = item.quantity;
                            }else{
                                product0.selected = false;
                                product0.quantity = 1;
                            }
                        });
                    }else{
                        product.selected = false;
                        product.quantity = 1;
                    }
                }
            });
        },

        sortProducts() {
            this.products.sort(function (a, b) {
                let c1 = 0;
                let c2 = 0;

                if (a.children === false) c1=0;else c1=a.children.length;
                if (b.children === false) c2=0;else c2=b.children.length;

                if (c1 < c2) return 1;
                else if (c1 > c2) return -1;
                else return 0;
            });
        },

        close() {
            this.$emit('close');
        },

        save() {
            this.$emit('save');
        }
    },

    computed: {
        filtredData(){
          var filter = this.filter;
            let _this = this;
          return this.products.filter(function (elem) {
              if (filter === '') return true;
              if (_this.ether_id == -1) return true;
              if (elem.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true;
              if (elem.id == -1) return true;
          });
        },
        rule_tv_admin: function (){return this.$store.getters.getRule('tv_admin') },
        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },
        currency: function () {
            let loc = this.$store.getters.getLocal;
            return loc.currency;
        }
    }
};
</script>

<style>
    table#tableProductEther {
        width: 100%;
    }
    table#tableProductEther th {
        text-align: center;
    }

    table#tableProductEther > thead,
    table#tableProductEther > tbody {
        display: block;
    }

    table#tableProductEther > thead > tr:after,
    table#tableProductEther > tbody > tr:after {
        content: ' ';
        display: block;
        visibility: hidden;
        clear: both;
    }

    table#tableProductEther > tbody {
        height: 550px;
        overflow-y: auto;
    }

    .at-active-ether {
        background-color: #afc8ff;
    }
</style>