<script>
    import bus from "@/bus";
    import Axios from 'axios';
    import Vue from 'vue';


    export default {
        name: "CallHistory",

        computed: {
            prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },
            rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
            rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
            rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
            rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
            rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') },
            orderBus: function (){ return this.$store.getters.getOrderBus }
        },

        data: function () {
            return {
                loading:    true,
                data:       [],
                phone:      ''
            }
        },

        methods: {
            hideModal() {
                this.$bvModal.hide('modal-call-history');
            },

            loadData() {
                this.loading = true;
                let url = this.prefix_url + "/api/calls?ts="+Date.now()+'&type=phone&phone='+this.phone;

                Axios
                        .get(url)
                        .then(response => {
                            this.data               = response.data.data;
                            this.$bvModal.show('modal-call-history');
                            this.loading            = false;
                });
            },
            showOrder(data) {
//                console.log('show_order', data);
                this.orderBus.$emit('show-order', {ether_id: data.ether_id, order_id: data.order_id, readonly: true });
            },
        },

        mounted() {
            bus.$on('Calls::call-history',(data) => {
                Object.assign(this.$data, this.$options.data.apply(this));
                this.phone  = data.phone;
                this.loadData();
            });
        },

        beforeDestroy() {
            bus.$off('Calls::call-history');
        }
    }
</script>

<template>
    <div>
        <b-modal id="modal-call-history" hide-footer size="xl">
            <template #modal-title>
                История звонков номера {{phone}}
            </template>
            <table class="table">
                <tr>
                    <th scope="col">{{$t('main.date')}}</th>
                    <th scope="col">{{$t('main.cause')}}</th>
                    <th scope="col">{{$t('main.status')}}</th>
                    <th scope="col">{{$t('helpdesk.entity.comment')}}</th>
                    <th scope="col">Retail ID</th>
                </tr>
                <tr v-for="(item, index) in data" :key="item.id">
                    <td>{{item.ctime}}</td>
                    <td>{{item.cause}}</td>
                    <td>{{item.state}}</td>
                    <td>{{item.comment}}</td>
                    <td>
                        <button class="btn btn-info"  v-if="item.rid!=''" @click="showOrder(item)">
                            {{item.rid}}
                        </button>
                    </td>
                </tr>
            </table>
        </b-modal>
    </div>
</template>

<style scoped>

</style>