<template>
  <b-modal id="modaladdProduct" size="xl" no-close-on-esc hide-footer>
      <template #modal-header="{ close }">
          <b-container fluid>
              <b-row>
                  <b-col>
                      <h5>{{$t('add_product.adding_a_product')}}</h5>
                  </b-col>
                  <b-col cols="6" class="text-right" style="padding-right: 0px;">
                      <b-form inline style="display: block">
                          <b-form-input :placeholder="$t('add_product.catalog_search')" @input="searchData" v-model="filter" size="sm" style="width: 70%; margin-right: 10px"></b-form-input>
                          <b-button size="sm" @click="close()" >
                              <b-icon-x></b-icon-x>
                          </b-button>
                      </b-form>
                  </b-col>
              </b-row>
          </b-container>
      </template>
      <div class="container-flex">
        <div class="row">
          <div class="col-sm-12" style="min-height: 600px;max-height: 600px;overflow-y: scroll">
            <table class="table table-sm">
              <thead>
              <tr>
                <th scope="col">{{$t('main.picture')}}</th>
                <th scope="col">{{$t('main.product')}}</th>
                <th scope="col">{{$t('main.quantity')}}</th>
                <th scope="col">{{$t('main.in_stock')}}</th>
                <th scope="col">{{$t('main.price')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in products" :key="product.id" v-bind:class="{ 'alert-success': product.selected }">
                <template v-if="product.children===false" >
            	    <td width="10%"><ExpandImage width="100" thumbnail fluid :src="product.image"></ExpandImage></td>
            	    <td width="70%">
                    <a href="#" @click="selectProduct(product, $event)">
                      {{ product.name }}
                      <template v-if="product.not_use_discount=='true'">
                        <b-icon-shield-x></b-icon-shield-x>
                      </template>
                    </a>
                  </td>
            	    <td width="10%"><input type="number" min="1" style="width: 50px" v-model="product.quantity" class="text-center" @change="changeProduct(product, $event)"></td>
            	    <td width="5%">{{ product.stock }}</td>
            	    <td width="5%">{{ product.amount }} {{currency}}</td>
                </template>
                <template v-else>
            	    <td colspan="5">
            		<table width="100%" border="0">
            		    <tr>
            			<td width="10%"><b-img width="100" thumbnail fluid :src="product.image"></b-img></td>
            			<td width="90%" colspan="4">
            			    <a href="#" @click="expandProduct(product, $event)">
            				<template v-if="product.expanded">
            				    <b>{{ product.name }}</b>
            				</template>
            				<template v-else>
            				    {{ product.name }}
            				</template>
            			    </a>
            			</td>
            		    </tr>
            		    <tr v-if="product.expanded" v-for="(p, i) in product.children" v-bind:class="{ 'alert-success': p.selected }">
            			<td width="10%"><!--b-img width="100" thumbnail fluid :src="p.image"></b-img--></td>
            			<td width="70%"><a href="#" @click="selectProduct(p, $event)">{{ p.name }}</a></td>
            			<td width="10%"><input type="number" min="1" style="width: 50px" v-model="p.quantity" class="text-center" @change="changeProduct(p, $event)"></td>
            			<td width="5%">{{ p.stock }}</td>
            			<td width="5%">{{ p.amount }} {{currency}}</td>
            		    </tr>
            		</table>
            	    </td>
            	</template>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </b-modal>
</template>

<script>
import Axios from 'axios';
import bus from "../bus";
import debounce from 'debounce';
import ExpandImage from "@/components/ExpandImage.vue";

export default {
  name: 'addProduct',
  components: {
        ExpandImage
  },

  data: function () {
    return {
      treeData: [],
      products: [],               // массив товаров
      pageNumber: 0,              // текущая страница
      filter: '',                 // фильтр
      data: []
    }
  },

  mounted() {
    this.loadData();
  },

  props: {
    details: {
      type: Boolean,
      required: false,
      default: true
    },

    site: {
      type: String,
      required: true,
      default: ''
    },

    size: {
      type: Number,
      required: false,
      default: 30
    },
  },

  methods: {
    searchData(){
        debounce((function (_this, event) {
          _this.$http
              .get(_this.prefix_url + '/api/products2?ts=' + Date.now() + '&query='+event.target.value+'&site='+_this.site+'&details='+_this.details)
              .then(response => {
                _this.products = response.data.products;
                _this.sortProducts();
              });
        })(this, event), 200);
    },

    loadData() {
      Axios
          .get(this.prefix_url + '/api/products2/?ts=' + Date.now()+'&site='+this.site+'&details='+this.details)
          .then(response => {
            this.products = response.data.products;
            this.sortProducts();
          });
    },

    changeFilter() {
      if (this.pageNumber!=0) this.pageNumber = 0;
    },

    selectProduct(product, e){
	    let value = e.target.value;
	    if (!product.selected) {
	        product.selected = true;
	        this.$emit('addProduct', product);
	        this.data.push({ id: product.id, quantity: product.quantity });
	    } else {
	        product.selected = false;
	        product.quantity = 1;
	        this.$emit('delProduct', product);
	    }
    },

    changeProduct(product, e){
	    let value = e.target.value;
	    product.quantity = value;
	    product.selected = true;
	    this.$emit('addProduct', product);
    },

    expandProduct(product, e){
	    product.expanded = !product.expanded;
    },

    preload() {
	    var _this = this;
	    if (this.data.length == 0) return;

	    this.products.forEach(function (product) {
            var obj = _this.data.find(item => item.id === product.id);
            if (typeof obj !== 'undefined') {
        	    product.selected = true;
        	    product.quantity = item.quantity;
    	    }else{
    		    if (typeof product.children !== 'undefined' && product.children !== false) {
                    product.children.forEach(function (product0) {
        		        var obj0 = _this.data.find(item => item.id === product0.id);
        		        if (typeof obj0 !== 'undefined') {
        		            product0.selected = true;
        		            product0.quantity = item.quantity;
    			        }else{
    			            product0.selected = false;
    			            product0.quantity = 1;
    			        }
    		        });
    		    }else{
    		        product.selected = false;
    		        product.quantity = 1;
    		    }
	        }
        });
    },

    sortProducts() {
      this.products.sort(function (a, b) {
        let c1 = 0;
        let c2 = 0;

        if (a.children === false) c1=0;else c1=a.children.length;
        if (b.children === false) c2=0;else c2=b.children.length;

        if (c1 < c2) return 1;
        else if (c1 > c2) return -1;
        else return 0;
      });
    },

    close(e) {
      this.$emit('close');
    },

    save() {
        this.$emit('save');
    },
    onEsc(e){

    }
  },

  computed : {
      prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },
      currency: function () {
          let loc = this.$store.getters.getLocal;
          return loc.currency;
      }
  }
};
</script>
