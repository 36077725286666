<template>
    <div>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show" inline class="m-3">
            <b-form-group
                    id="input-group-1"
                    label="Retail ID"
                    label-for="input-1">
                <b-form-input
                        id="input-1"
                        class="m-2 p-4"
                        v-model="form.orderid"
                        placeholder="">
                </b-form-input>

            </b-form-group>

            <b-form-group id="input-group-2" :label="$t('main.phone')" label-for="input-2">
                <b-form-input
                        id="input-2"
                        class="m-2 p-4"
                        type="tel"
                        v-model="form.phone"
                        placeholder="">
                </b-form-input>
            </b-form-group>
            <div class="ml-4">
                <b-button type="submit" variant="primary" :disabled="!active">
                    <template v-if="active">{{$t('main.search')}}</template>
                    <template v-else>
                        <b-spinner small type="grow"></b-spinner> Loading...
                    </template>
                </b-button>
            </div>
            <b-button type="reset" variant="danger" class="ml-3">{{$t('main.reset_filter')}}</b-button>

        </b-form>
        <div>
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Retail_ID</th>
                    <th scope="col">{{$t('search.date_of_confirmation_of_opp')}}</th>
                    <th scope="col">{{$t('main.customer')}}</th>
                    <th scope="col">{{$t('main.payment')}}</th>
                    <th scope="col">{{$t('main.amount')}}</th>
                    <th scope="col">{{$t('main.status')}}</th>
                    <th scope="col">{{$t('main.region')}}</th>
                    <th scope="col">{{$t('main.planned_delivery_date')}}</th>
                    <th scope="col">{{$t('main.address')}}</th>
                    <th scope="col">{{$t('search.days_on_the_way')}}</th>
                    <th scope="col">{{$t('search.mark')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in results" :key="item.id">
                    <td>{{index + 1}}</td>
                    <td>
                        <template v-if="item.track!=''">
                            <a :href="item.track" target="_blank">
                                {{item.number}}
                            </a>
                        </template>
                        <template v-else>
                            {{item.number}}
                        </template>
                    </td>
                    <td><nobr>{{item.ctime}}</nobr></td>
                    <td>{{item.name}}</td>
                    <td><nobr>{{item.pay}}</nobr></td>
                    <td><nobr>{{item.amount}} s`om</nobr></td>
                    <td>{{item.status}}</td>
                    <td>{{item.region}}</td>
                    <td>{{item.plan_date}}</td>
                    <td>{{item.address}}</td>
                    <td>{{item.days}}</td>
                    <td v-if="item.note" class="text-danger"><b>VIP</b></td>
                    <td v-else></td>
                </tr>
                </tbody>
            </table>
        </div>
        <b-navbar fixed="bottom" type="light" variant="light" style="border-top: 1px solid rgba(0, 0, 0, 0.1);">
            <b-button variant="danger" @click="$router.back()" style="margin-left: 10px">
                {{$t('main.close')}}
            </b-button>
        </b-navbar>
    </div>
</template>

<script>
    import Axios from 'axios';
    import bus from "@/bus";

    export default {
        name: 'OrderSearch',
        data() {
            return {
                form: {
                    phone: '',
                    orderid: ''
                },
                results: [],
                show: true,
                active: true
            }
        },

        metaInfo() {
            return {
                title: 'Поиск заказов - панель оператора HopShop'
            }
        },

        computed: {
            min_state() {
                return (this.form.phone.length > 0 || this.form.orderid.length > 0)
            }
        },

        mounted() {
            bus.$emit('set-header', 'Панель оператора :: Поиск Заказов\n');
        },

        methods: {
            loadData(type, query) {                  // загрузка данных с backend
                this.active = false;
                Axios
                        .post(this.prefix_url + '/api/searchorders?ts=' + Date.now(), {
                            type: type,
                            query: query
                        })
                        .then(response => {
                            console.log(response);
                            this.results = response.data.search;
                            this.active = true;
                });
            },

            onSubmit(event) {
                event.preventDefault();
                if (this.form.phone=='' && this.form.orderid!='') this.loadData('number', this.form.orderid);
                else if (this.form.phone!='') this.loadData('phone', this.form.phone);
            },

            onReset(event) {
                event.preventDefault();
                this.form.phone = '';
                this.form.orderid = '';
                this.show = false;
                this.results = [];
                this.$nextTick(() => {
                    this.show = true
                })
            }
        },

        computed : {
            prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
        }
    }
</script>
<style scoped>

</style>