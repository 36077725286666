<script>
import bus from "@/bus";
import Axios from "axios";

export default {
  name: "helpdeskModal",
  computed: {
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },
    rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
    rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
    rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
    rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
    rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') }
  },
  data () {
    return {
      data: {
        customer_phone: '',
        type_ticket: '',
        comment_ticket: '',
        order_id: '',
        call_id: 0
      },
      so: {
        options_ticket: [],
        type_readonly: false,
        phone_readonly: false
      },
    }
  },
  mounted() {
    bus.$on('Tickets::append',(data) => {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$bvModal.show('modal-create-ticket');
      this.setup(data)
    })
  },
  methods: {
    ValidPhone(phone) {
      // let str = phone.replace(/\D/g, '');
      // var re = /^\d{9}$/;
      // if (typeof process.env.VUE_APP_COUNTRY === 'undefined' || process.env.VUE_APP_COUNTRY == 'uz' || process.env.VUE_APP_COUNTRY == 'pl') re = /^\d{9}$/;
      // else if (process.env.VUE_APP_COUNTRY == 'kz') re = /^\+?7?\d{10}$/;
      // else re = /^\+?7?\d{10}$/;
      //
      // return re.test(phone);
      return true
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },

    setup (data) {
      this.data.call_id         = data.call_id || 0;
      this.data.order_id = data.order_id || '';
      this.data.customer_phone  = data.phone || '';
      this.type                 = data.type || 'service';

      if (data.phone) this.so.phone_readonly = true;

      switch (this.type) {
        case "service":
          this.so.options_ticket = [
            {value: '', text: this.$t('orders.modal.select_the_type_of_ticket'), disabled: true},
            {value: 'product', text: this.$t('orders.modal.complaint_about_the_product') },
            {value: 'delivery', text: this.$t('orders.modal.delivery_complaint') }
          ];
          break;
        case "lead":
            this.so.options_ticket = [
              {value: 'lead', text: this.$t('orders.modal.lead_call'), disabled: true},
            ];
            this.data.type_ticket = "lead";
            this.so.type_readonly = true;
            break;
        case "wrong_call":
            this.so.options_ticket = [
              {value: 'wrong_call', text: this.$t('orders.modal.wrong_call'), disabled: true},
            ];
            this.so.type_readonly = true;
            this.data.type_ticket = "wrong_call";
            break;
        case "cancel":
          this.so.options_ticket = [
            {value: 'cancel', text: this.$t('orders.modal.cancel_call'), disabled: true},
          ];
          this.so.type_readonly = true;
          this.data.type_ticket = "cancel";
          break;
      }
    },

    sendTicket(){
      if (this.data.customer_phone === '') {
        bus.$emit('view-message', { message: this.$t('main.enter_client_phone_number'), variant: 'danger' });
        this.focusRef(this.$refs['orders_phone']);
        return;
      }

      if (!this.ValidPhone(this.data.customer_phone)) {
        bus.$emit('view-message', { message: this.$t('main.invalid_client_phone_number_format'), variant: 'danger' });
        this.focusRef(this.$refs['orders_phone']);
        return;
      }
      if (this.data.type_ticket === '') {
        bus.$emit('view-message', { message: this.$t('main.select_type_of_complaint'), variant: 'danger' });
        this.focusRef(this.$refs['orders_type_ticket']);
        return;
      }
// send_data
      Axios
          .post(this.prefix_url + '/api/helpdesk?ts=' + Date.now(), {
            method: 'save',
            data:   this.data
          }).then(res => {
                if (res.data.success) {
                    bus.$emit('view-message', {message: this.$t('main.ticket_created_successfully', { number: res.data.number}), variant: 'success'});
                    bus.$emit('end-call', parseInt(this.data.call_id));
                    this.data.call_id = 0;
                }else{
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                }
      });
      this.$bvModal.hide('modal-create-ticket');
    }
  },
  beforeDestroy() {
    bus.$off('Tickets::append');
  },
}
</script>

<template>
  <div>
    <b-modal id="modal-create-ticket" size="lg">
      <template #modal-title>
        {{ $t('orders.modal.create_ticket')}}
      </template>
      <template #modal-footer>
        <b-button style="margin-left: 10px" class="mt-3 btn btn-success" @click="sendTicket" :disabled="data.customer_phone==='' || data.type_ticket==='' || data.comment_ticket===''">{{ $t('main.save')}}</b-button>
        <b-button style="margin-left: 10px" class="mt-3" @click="$bvModal.hide('modal-create-ticket')">{{ $t('main.close')}}</b-button>
      </template>
      <b-row>
        <b-col>{{ $t('orders.modal.phone')}}</b-col>
        <b-col>
          <div class="input-group">
            <input type="phone" :placeholder="$t('main.enter_client_phone_number')" class="form-control" :readonly="so.phone_readonly" v-model="data.customer_phone" autocomplete="off" ref="orders_phone">
          </div>
        </b-col>
      </b-row>
      <br/>
      <div v-if="data.order_id !== ''">
        <b-row>
          <b-col>{{ $t('main.order_id')}}</b-col>
          <b-col>
            <div class="input-group">
              <input type="text" class="form-control" readonly v-model="data.order_id" autocomplete="off">
            </div>
          </b-col>
        </b-row>
        <br/>
      </div>
      <b-row>
        <b-col>{{ $t('orders.modal.type_ticket')}}</b-col>
        <b-col><b-form-select v-model="data.type_ticket" :options="so.options_ticket" :disabled="so.type_readonly" ref="order_type_ticket"></b-form-select></b-col>
      </b-row>
      <br/>
      <b-row>
        <b-col>{{ $t('orders.modal.comment')}}</b-col>
        <b-col>
          <b-form-textarea
              :placeholder="$t('orders.modal.comment')"
              v-model="data.comment_ticket"
              rows="3"
              max-rows="6"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style scoped>

</style>